import path from 'path'
import UnProtectedRoute from './UnProtectedRoute'
import routeUrls from '../constants/routeUrls'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import Login from '../pages/Login'
import PageNotFound from '../pages/PageNotFound'
import Dashboard from '../pages/Dashboard'
import MainLayout from '../layouts/MainLayout'
import DistrictAdminsManagement from '../pages/DistrictAdminsManagement'
import UsersManagement from '../pages/UsersManagement'
import ProductsManagement from '../pages/ProductsManagement'
import GiftsManagement from '../pages/GiftsManagement'
import RedeemPointsManagement from '../pages/RedeemPointsManagement'
import DistrictListManagement from '../pages/DistrictListManagement'
import VisitingCardManagement from '../pages/VisitingCardManagement'
import EventsManagement from '../pages/EventsManagement'
import Notifications from '../pages/Notifications/indext'
import UsersReports from '../pages/Reports/UsersReports'
import StateListManagement from '../pages/StateListManagement'
import QRCodeManagement from '../pages/QRCodeManagement'
import LogsReports from '../pages/Reports/LogsReports'
import StockManagement from '../pages/StockManagement'
import StockTransaction from '../pages/StockTransaction'

const Routes = () => {
  const routesNotFound = [
    {
      path: routeUrls.PAGENOTFOUND,
      element: <PageNotFound />,
    },
  ]

  const unprotectedRoutes = [
    {
      path: '/',
      element: <UnProtectedRoute />,
      children: [
        {
          path: '/',
          element: <Login />,
        },
        {
          path: routeUrls.LOGIN,
          element: <Login />,
        },
      ],
    },
  ]

  const protectedRoutes = [
    {
      path: '/',
      element: <ProtectedRoute />,
      children: [
        {
          path: '/',
          element: <MainLayout />,
          children: [
            {
              path: routeUrls.DASHBOARD,
              element: <Dashboard />,
            },
            {
              path: routeUrls.STATELISTMANAGEMENT,
              element: <StateListManagement />,
            },
            {
              path: routeUrls.DISTRICTADMINSMANAGEMENT,
              element: <DistrictAdminsManagement />,
            },
            {
              path: routeUrls.USERSMANAGEMENT,
              element: <UsersManagement />,
            },
            {
              path: routeUrls.PRODUCTSMANANGEMENT,
              element: <ProductsManagement />,
            },
            {
              path: routeUrls.GIFTSMANANGEMENT,
              element: <GiftsManagement />,
            },
            {
              path: routeUrls.REDEEMPOINTSMANANGEMENT,
              element: <RedeemPointsManagement />,
            },
            {
              path: routeUrls.DISTRICTLISTMANANGEMENT,
              element: <DistrictListManagement />,
            },
            {
              path: routeUrls.VISITINGCARDMANANGEMENT,
              element: <VisitingCardManagement />,
            },
            {
              path: routeUrls.EVENTSMANANGEMENT,
              element: <EventsManagement />,
            },
            {
              path: routeUrls.QRCODEMANAGEMENT,
              element: <QRCodeManagement />,
            },
            {
              path: routeUrls.STOCKMANAGEMENT,
              element: <StockManagement />,
            },
            {
              path: routeUrls.STOCKTRANSACTION,
              element: <StockTransaction />,
            },
            {
              path: routeUrls.USERSREPORTS,
              element: <UsersReports />,
            },
            {
              path: routeUrls.LOGSREPORTS,
              element: <LogsReports />,
            },
            {
              path: routeUrls.NOTIFICATIONS,
              element: <Notifications />,
            },
          ],
        },
      ],
    },
  ]

  const router = createBrowserRouter([
    ...unprotectedRoutes,
    ...protectedRoutes,
    ...routesNotFound,
  ])

  return <RouterProvider router={router} />
}

export default Routes
