import * as yup from 'yup'

export const addModalValidationSchema = yup.object().shape({
  product: yup.number().required('Product are required'),
  productName: yup.string().required('Product Name are required'),
  batchNumber: yup.string().required('Batch Number are required'),
  packing: yup
    .number()
    .typeError('Packing are required')
    .required('Packing are required')
    .positive('Packing must be a positive number')
    .integer('Packing must be an number'),
  points: yup
    .number()
    .typeError('Points are required')
    .required('Points are required')
    .positive('Points must be a positive number')
    .integer('Points must be an number'),
  quantity: yup
    .number()
    .typeError('Quantity are required')
    .required('Quantity are required')
    .positive('Quantity must be a positive number')
    .integer('Quantity must be an number'),
})
