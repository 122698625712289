import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import PeopleIcon from '@mui/icons-material/People'
import StoreIcon from '@mui/icons-material/Store'
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined'
import EventIcon from '@mui/icons-material/Event'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useStateDetailsQuery } from '../../api/stateApi'
import { DashboardProps } from './types'
import { useDistrictDetailsQuery } from '../../api/districtApi'
import { useDistrictAdminDetailsQuery } from '../../api/districtAdminApi'
import { useApprovedUsersDetailsQuery } from '../../api/userApi/approvedUsersApi'
import { useProductsDetailsQuery } from '../../api/productsApi'
import { useGiftDetailsQuery } from '../../api/giftsApi'
import { useEventDetailsQuery } from '../../api/eventsApi'

const useDashboard = () => {
  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )

  // State list
  const { state: stateQueryParams } = listingQueryParams
  const { data: stateDataList, isSuccess: isStateDataSuccess } =
    useStateDetailsQuery(stateQueryParams)

  // District List
  const { district: districtQueryParams } = listingQueryParams
  const { data: districtDataList, isSuccess: isDistrictDataSuccess } =
    useDistrictDetailsQuery(districtQueryParams)

  // District Admins
  const { districtAdmin: districtAdminQueryParams } = listingQueryParams
  const { data: districtAdminDataList, isSuccess: isDistrictAdminDataSuccess } =
    useDistrictAdminDetailsQuery(districtAdminQueryParams)

  // Users
  const { approvedUsers: approvedUsersQueryParams } = listingQueryParams
  const { data: users, isSuccess: isUserSuccess } =
    useApprovedUsersDetailsQuery(approvedUsersQueryParams)

  // Products
  const { products: productsQueryParams } = listingQueryParams
  const { data: productDataList, isSuccess: isProductSuccess } =
    useProductsDetailsQuery(productsQueryParams)

  // Gifts
  const { gift: giftQueryParams } = listingQueryParams
  const { data: giftDataList, isSuccess: isGiftSuccess } =
    useGiftDetailsQuery(giftQueryParams)

  // Events
  const { events: eventsQueryParams } = listingQueryParams
  const { data: eventDataList, isSuccess: isEventSuccess } =
    useEventDetailsQuery(eventsQueryParams)

  const dashboardList: DashboardProps[] = [
    {
      title: 'States',
      color: 'blue',
      count: isStateDataSuccess ? stateDataList?.count || 0 : '-',
      Icon: PublicOutlinedIcon,
      xs: 12,
      lg: 4,
    },
    {
      title: 'Districts',
      color: 'deepPurple',
      count: isDistrictDataSuccess ? districtDataList?.count || 0 : '-',
      Icon: LocationCityIcon,
      xs: 12,
      lg: 4,
    },
    {
      title: 'District Admins',
      color: 'green',
      count: isDistrictAdminDataSuccess
        ? districtAdminDataList?.count || 0
        : '-',
      Icon: AdminPanelSettingsIcon,
      xs: 12,
      lg: 4,
    },
    {
      title: 'Contractors',
      color: 'indigo',
      count: isUserSuccess ? users?.count || 0 : '-',
      Icon: PeopleIcon,
      xs: 12,
      lg: 4,
    },
    {
      title: 'Products',
      color: 'lightBlue',
      count: isProductSuccess ? productDataList?.count || 0 : '-',
      Icon: StoreIcon,
      xs: 12,
      lg: 4,
    },
    {
      title: 'Gifts',
      color: 'pink',
      count: isGiftSuccess ? giftDataList?.count || 0 : '-',
      Icon: CardGiftcardIcon,
      xs: 12,
      lg: 4,
    },
    {
      title: 'Events',
      color: 'red',
      count: isEventSuccess ? eventDataList?.count || 0 : '-',
      Icon: EventIcon,
      xs: 12,
      lg: 4,
    },
  ]

  return { dashboardList }
}

export default useDashboard
