import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ListingResponse } from '../types'
import { API_METHODS } from '../../constants/contants'
import { API_ENDPOINTS } from '../../constants/apiendpoints'
import { StocksManagementApiTags } from './tags'
import { AddStockResponse, StocksPayload, StocksResponse } from './types'

export const stocksManagementApi = createApi({
  reducerPath: 'stockManagementApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [StocksManagementApiTags.STOCK_CREATED],
  endpoints: (builder) => ({
    // Get All Stocks
    getAllStocksDetails: builder.query<
      ListingResponse<StocksResponse[]>,
      StocksPayload
    >({
      query: (params: StocksPayload) => {
        return {
          url: API_ENDPOINTS.GET_ALL_STOCKS,
          method: API_METHODS.GET,
          params,
        }
      },
      providesTags: [StocksManagementApiTags.STOCK_CREATED],
      transformResponse: (response: ListingResponse<StocksResponse[]>) => {
        return response
      },
    }),

    // Add Stock
    addStock: builder.mutation<AddStockResponse, FormData>({
      query: (params: FormData) => ({
        url: API_ENDPOINTS.ADD_STOCK,
        method: API_METHODS.POST,
        body: params,
      }),
      invalidatesTags: [StocksManagementApiTags.STOCK_CREATED],
      transformResponse: (response: AddStockResponse) => {
        return response
      },
    }),
  }),
})

export const { useGetAllStocksDetailsQuery, useAddStockMutation } = stocksManagementApi
