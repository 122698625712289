import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import useAddModal from './useAddModal'
import InputField from '../../../components/InputField'
import ButtonField from '../../../components/ButtonField'
import { AddModalForm, AddQRCodeModalProps } from './types'
import { useEffect, useState } from 'react'
import SelectField from '../../../components/SelectField'
import { useProductsDetailsQuery } from '../../../api/productsApi'
import { RootState } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import { setListingQueryParams } from '../../../store/slice/commonSlice'
import { APP_CONFIG } from '../../../constants/contants'
import { ProductResponse } from '../../../api/productsApi/types'

const AddQRCodeModal: React.FC<AddQRCodeModalProps> = ({
  openModal,
  handleCloseModal,
}) => {
  const {
    control,
    handleSubmit,
    onSubmit,
    errors,
    isLoading,
    reset,
    watch,
    setValue,
  } = useAddModal({
    openModal,
    handleCloseModal,
  })

  const dispatch = useDispatch()
  const [selectedProduct, setSelectedProduct] = useState<
    ProductResponse | undefined
  >()

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )

  // Product List Query
  const [productSearch, setProductSearch] = useState('')
  const { products: productsQueryParams } = listingQueryParams

  const {
    data: productList,
    isFetching: isProductFetching,
    isSuccess: isProductSuccess,
  } = useProductsDetailsQuery(productsQueryParams)

  useEffect(() => {
    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        products: {
          q: productSearch,
          page: APP_CONFIG.DEFAULT_PAGE,
          page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
        },
      }),
    )
  }, [productSearch, openModal])

  useEffect(() => {
    reset()
  }, [openModal])

  useEffect(() => {
    if (selectedProduct) {
      setValue('productName', selectedProduct?.product_name || '')
      setValue('points', selectedProduct?.points || 0)
      setValue('packing', selectedProduct?.packing || 0)
    }
  }, [selectedProduct, setValue])

  return (
    <Dialog
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
        },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* Title */}
      <DialogTitle sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }} id='add-qrcode'>
        Create Bar Code
      </DialogTitle>

      {/* Dialog box close button */}
      <IconButton
        onClick={handleCloseModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Grid container spacing={1}>
          {/* Products List Field */}
          <Grid item xs={12} mt={2}>
            <SelectField
              label={'Products List'}
              name={'product'}
              options={productList?.results}
              loading={!isProductSuccess && isProductFetching}
              control={control}
              getOptionLabel={(option) => option.product_name} // Use 'name' as the label
              getOptionValue={(option) => option.id} // Use 'id' as the value
              onSearch={setProductSearch}
              onSelect={(selectedItem) => {
                setSelectedProduct(selectedItem)
              }}
              errors={!!errors.packing}
              helperText={errors.packing ? errors.packing.message : ''}
            />
          </Grid>

          {/* Product Name Field */}
          <Grid item xs={12} sm={6}>
            <InputField
              name={'productName'}
              control={control}
              label={'Product Name'}
              fullWidth
              errors={!!errors.productName}
              helperText={errors.productName ? errors.productName.message : ''}
              required
              disabled
            />
          </Grid>

          {/* Batch Number Field */}
          <Grid item xs={12} sm={6}>
            <InputField
              name={'batchNumber'}
              control={control}
              label={'Batch Number'}
              fullWidth
              errors={!!errors.batchNumber}
              helperText={errors.batchNumber ? errors.batchNumber.message : ''}
              required
            />
          </Grid>

          {/* Packing Field */}
          <Grid item xs={12}>
            <InputField
              name={'packing'}
              control={control}
              label={'Packing'}
              type={'number'}
              fullWidth
              endAdornment={
                <InputAdornment position='start'>Kg</InputAdornment>
              }
              errors={!!errors.packing}
              helperText={errors.packing ? errors.packing.message : ''}
              required
              disabled
            />
          </Grid>

          {/* Points */}
          <Grid item xs={12} sm={6}>
            <InputField
              name={'points'}
              control={control}
              label={'Points Per Kg'}
              type={'number'}
              placeholder='Enter the points'
              fullWidth
              required
              errors={!!errors.points}
              helperText={errors.points ? errors.points.message : ''}
            />
          </Grid>

          {/* Total Points Per Product */}
          <Grid item xs={12} sm={6}>
            <InputField
              name={'totalPoints'}
              control={control}
              label={'Total Points Per Product'}
              type={'number'}
              fullWidth
              required
              disabled
            />
          </Grid>

          {/* Quantity (In Count)*/}
          <Grid item xs={12} sm={6}>
            <InputField
              name={'quantity'}
              control={control}
              label={'Quantity (In Count)'}
              type={'number'}
              placeholder='Enter the token quantity'
              fullWidth
              required
              errors={!!errors.quantity}
              helperText={errors.quantity ? errors.quantity.message : ''}
            />
          </Grid>

          {/* Quantity (In Kg)*/}
          <Grid item xs={12} sm={6}>
            <InputField
              name={'totalQuantity'}
              control={control}
              label={'Quantity (In Kg)'}
              type={'number'}
              fullWidth
              required
              disabled
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <ButtonField
          variant='contained'
          onClick={handleCloseModal}
          buttonText={'Cancel'}
          sx={{
            backgroundColor: 'primary.main',
          }}
        />

        <ButtonField
          type='submit'
          loading={isLoading}
          variant='contained'
          buttonText={'Save'}
          sx={{
            backgroundColor: 'ternary.main',
            '&:hover': {
              backgroundColor: 'ternary.dark',
            },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export default AddQRCodeModal
