import { FC, useEffect } from 'react'
import { Grid } from '@mui/material'
import InputField from '../../../../../components/InputField'
import { useForm } from 'react-hook-form'
import { UserDetailsForm } from './types'

const UserDetails: FC<any> = ({ data }) => {
  const { control, setValue } = useForm<UserDetailsForm>({
    defaultValues: {
      userName: '',
      mobileNumber: '',
      district: '',
      state: '',
    },
  })

  useEffect(() => {
    if (data) {
      setValue('userName', data.user_name)
      setValue('mobileNumber', data?.user_mobile)
      setValue('district', data?.district_name)
      setValue('state', data?.state_name)
    }
  }, [data, setValue])

  return (
    <>
      <Grid container spacing={1}>
        {/* User Name Field */}
        <Grid item xs={12} sm={6}>
          <InputField
            name={'userName'}
            control={control}
            label={'Contractor Name'}
            placeholder='Enter the contractor name'
            fullWidth
            readOnly
          />
        </Grid>

        {/* Mobile Number */}
        <Grid item xs={12} sm={6}>
          <InputField
            name={'mobileNumber'}
            control={control}
            label={'Mobile Number'}
            placeholder='Enter the Mobile Number'
            fullWidth
            readOnly
          />
        </Grid>

        {/* State */}
        <Grid item xs={12} sm={6}>
          <InputField
            name={'state'}
            control={control}
            label={'State'}
            placeholder='Enter the State Name'
            fullWidth
            readOnly
          />
        </Grid>

        {/* District */}
        <Grid item xs={12} sm={6}>
          <InputField
            name={'district'}
            control={control}
            label={'District'}
            placeholder='Enter the District Name'
            fullWidth
            readOnly
          />
        </Grid>
      </Grid>
    </>
  )
}

export default UserDetails
