import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ListingResponse } from '../types'
import { API_METHODS } from '../../constants/contants'
import { API_ENDPOINTS } from '../../constants/apiendpoints'
import {
  LogsReportPayload,
  LogsReportResponse,
  UserReportPayload,
  UserReportResponse,
} from './types'

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  endpoints: (builder) => ({
    // Get All Reports List
    userReportDetails: builder.query<
      ListingResponse<UserReportResponse[]>,
      UserReportPayload
    >({
      query: (params: UserReportPayload) => {
        return {
          url: API_ENDPOINTS.GET_ALL_REPORTS,
          method: API_METHODS.GET,
          params,
        }
      },
      transformResponse: (response: ListingResponse<UserReportResponse[]>) => {
        return response
      },
    }),

    // Get All Reports List
    logsReportDetails: builder.query<
      ListingResponse<LogsReportResponse[]>,
      LogsReportPayload
    >({
      query: (params: LogsReportPayload) => {
        return {
          url: API_ENDPOINTS.GET_ALL_LOGS_REPORTS,
          method: API_METHODS.GET,
          params,
        }
      },
      transformResponse: (response: ListingResponse<LogsReportResponse[]>) => {
        return response
      },
    }),
  }),
})

export const { useUserReportDetailsQuery, useLogsReportDetailsQuery } =
  reportsApi
