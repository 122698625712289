import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ListingResponse } from '../types'
import { API_METHODS } from '../../constants/contants'
import { API_ENDPOINTS } from '../../constants/apiendpoints'
import {
  StocksCreditedTransactionPayload,
  StocksCreditedTransactionResponse,
  StocksDebitedTransactionPayload,
  StocksDebitedTransactionResponse,
} from './types'

export const stocksTransactionApi = createApi({
  reducerPath: 'stocksTransactionApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  tagTypes: [],
  endpoints: (builder) => ({
    // Get All Credited Stocks Transaction
    getAllStocksCreditedTransactionList: builder.query<
      ListingResponse<StocksCreditedTransactionResponse[]>,
      StocksCreditedTransactionPayload
    >({
      query: (params: StocksCreditedTransactionPayload) => {
        return {
          url: API_ENDPOINTS.GET_ALL_STOCKS_CREDITED_TRANSACTIONS,
          method: API_METHODS.GET,
          params,
        }
      },
      transformResponse: (
        response: ListingResponse<StocksCreditedTransactionResponse[]>,
      ) => {
        return response
      },
    }),

    // Get All Debited Stocks Transaction
    getAllStocksDebitedTransactionList: builder.query<
      ListingResponse<StocksDebitedTransactionResponse[]>,
      StocksDebitedTransactionPayload
    >({
      query: (params: StocksDebitedTransactionPayload) => {
        return {
          url: API_ENDPOINTS.GET_ALL_STOCKS_DEBITED_TRANSACTIONS,
          method: API_METHODS.GET,
          params,
        }
      },
      transformResponse: (
        response: ListingResponse<StocksDebitedTransactionResponse[]>,
      ) => {
        return response
      },
    }),
  }),
})

export const {
  useGetAllStocksCreditedTransactionListQuery,
  useGetAllStocksDebitedTransactionListQuery,
} = stocksTransactionApi
