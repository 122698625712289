import HomeIcon from '@mui/icons-material/Home'
import TopBar from '../../components/TopBar'
import routeUrls from '../../constants/routeUrls'
import { Box, Button } from '@mui/material'
import DataTable from './DataTable'
import { useEffect, useState } from 'react'
import { APP_CONFIG } from '../../constants/contants'
import { AppDispatch, RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { useGetAllStocksDetailsQuery } from '../../api/stocksManagementApi'
import { setListingQueryParams } from '../../store/slice/commonSlice'
import AddStockModal from './AddStockModal'

const StockManagement = () => {
  const dispatch = useDispatch<AppDispatch>()

  // BreadCrumbsItems
  const breadcrumbsItems = [
    {
      to: routeUrls.DASHBOARD,
      text: 'Home',
      icon: <HomeIcon sx={{ mr: 0.5 }} fontSize='inherit' />,
    },
    { text: 'Stock Management' },
  ]

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )
  const { stockManagement: stockManagementQueryParams } = listingQueryParams

  // Page
  const [page, setPage] = useState(APP_CONFIG.DEFAULT_PAGE)
  const [pageSize, setPageSize] = useState(APP_CONFIG.DEFAULT_PAGE_SIZE)

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage + 1)
  }

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(APP_CONFIG.DEFAULT_PAGE)
  }

  // Search Value
  const [search, setSearch] = useState('')

  const handleSearchValue = (value: string) => {
    setSearch(value)
  }

  // Add Modal
  const [openAddModal, setOpenAddModal] = useState<boolean>(false)

  const handleOpenAddModal = () => {
    setOpenAddModal(true)
  }

  const handleCloseAddModal = () => {
    setOpenAddModal(false)
  }

  const { data, isFetching, isSuccess } = useGetAllStocksDetailsQuery(
    stockManagementQueryParams,
  )

  useEffect(() => {
    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        stockManagement: {
          q: search,
          page: page,
          page_size: pageSize,
        },
      }),
    )
  }, [search, page, pageSize])

  return (
    <>
      <TopBar title='Stock Management' breadcrumbsItems={breadcrumbsItems} />

      {/* Table */}
      <Box mt={2}>
        <DataTable
          headers={['#', 'Product Name', 'Total Quantity Available']}
          action={
            <Button
              variant='contained'
              onClick={handleOpenAddModal}
              sx={{ textTransform: 'none', fontWeight: 600 }}
            >
              Add Stock
            </Button>
          }
          data={data?.results}
          totalPages={data?.count}
          page={page}
          rowsPerPage={pageSize}
          handlePageChange={handlePageChange}
          handleSearchValue={handleSearchValue}
          handleChangeRowsPerPage={handleChangePageSize}
          isSuccess={isSuccess && !isFetching}
        />
      </Box>

      {/* Add Modal */}
      <AddStockModal
        openModal={openAddModal}
        handleCloseModal={handleCloseAddModal}
      />
    </>
  )
}

export default StockManagement
