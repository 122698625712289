import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { AddModalForm, AddModalProps } from './types'
import { addModalValidationSchema } from './validation'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../utils/toastMessage'
import { useAddStockMutation } from '../../../api/stocksManagementApi'
import { AddStockResponse } from '../../../api/stocksManagementApi/types'

const useAddModal = ({ handleCloseModal }: AddModalProps) => {
  const [addStock, { isLoading }] = useAddStockMutation()

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<AddModalForm>({
    defaultValues: {
      product: undefined,
      quantity: undefined,
    },
    resolver: yupResolver(addModalValidationSchema),
  })

  const onSubmit: SubmitHandler<AddModalForm> = async (data) => {
    const formData = new FormData()

    formData.append('product_id', data?.product.toString())
    formData.append('quantity', data?.quantity.toString())

    addStock(formData)
      .unwrap()
      .then((data: AddStockResponse) => {
        if (data?.success) {
          toastSuccessMessage('Stock Added Successfully')
          handleCloseModal()
        } else {
          toastErrorMessage(data?.message || 'Unable to Add Stock')
        }
      })
      .catch((error) => {
        toastErrorMessage(error?.data?.message || 'Unable to Add Stock')
      })
  }

  return {
    control,
    register,
    errors,
    onSubmit,
    handleSubmit,
    isLoading,
    reset,
  }
}

export default useAddModal
