import * as yup from 'yup'

export const addModalValidationSchema = yup.object().shape({
  product: yup.number().required('Product is required'),
  quantity: yup
    .number()
    .typeError('Quantity is required')
    .required('Quantity is required')
    .positive('Quantity must be a positive number')
    .integer('Quantity must be an number'),
})
