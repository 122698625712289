import HomeIcon from '@mui/icons-material/Home'

import { Box, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { APP_CONFIG, USER_STATUS } from '../../../constants/contants'
import DataTable from './DataTable'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../utils/toastMessage'
import { setListingQueryParams } from '../../../store/slice/commonSlice'
import ViewPendingUserModal from './ViewPendingUserModal'
import {
  usePendingInvitationUsersDetailsQuery,
  useUpdatePendingInvitationUserStatusMutation,
} from '../../../api/userApi/pendingInvitationUsersApi'
import { UpdatePendingInvitationUserStatusResponse } from '../../../api/userApi/pendingInvitationUsersApi/types'

const PendingInvitation = () => {
  const dispatch = useDispatch<AppDispatch>()

  // Page
  const [page, setPage] = useState(APP_CONFIG.DEFAULT_PAGE)
  const [pageSize, setPageSize] = useState(APP_CONFIG.DEFAULT_PAGE_SIZE)

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage + 1)
  }

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(APP_CONFIG.DEFAULT_PAGE)
  }

  // Search Value
  const [search, setSearch] = useState('')

  const handleSearchValue = (value: string) => {
    setSearch(value)
  }

  // View Modal
  const [openViewModal, setOpenViewModal] = useState({
    id: 0,
    open: false,
  })

  const handleOpenViewModal = (id: number) => {
    setOpenViewModal({
      id: id,
      open: true,
    })
  }

  const handleCloseViewModal = () => {
    setOpenViewModal({
      id: 0,
      open: false,
    })
  }

  // update pending invitation user status
  const [updatePendingInvitationUserStatus, { isLoading }] =
    useUpdatePendingInvitationUserStatusMutation()

  // Approved User Invitation
  const handleApprovedUserInvitation = (number: string) => {
    Swal.fire({
      title: 'Are you sure you want to approved user invitation?',
      icon: 'warning',
      showCancelButton: true,
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button',
        title: 'custom-title',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const formData = new FormData()

        formData.append('number', number.toString())
        formData.append('status', USER_STATUS.APPROVED)

        updatePendingInvitationUserStatus(formData)
          .unwrap()
          .then((data: UpdatePendingInvitationUserStatusResponse) => {
            if (data?.success) {
              toastSuccessMessage('Contractor has been Approved successfully')
            } else {
              toastErrorMessage(
                data?.message || 'Unable to Approved Contractor',
              )
            }
          })
          .catch((error) => {
            toastErrorMessage(
              error?.data?.message || 'Unable to Approved Contractor',
            )
          })
      }
    })
  }

  // Rejected User Invitation

  const handleDeclinedUserInvitation = (number: string) => {
    Swal.fire({
      title: 'Are you sure you want to declined contractor invitation?',
      icon: 'warning',
      showCancelButton: true,
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button',
        title: 'custom-title',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const formData = new FormData()

        formData.append('number', number.toString())
        formData.append('status', USER_STATUS.DENIED)

        updatePendingInvitationUserStatus(formData)
          .unwrap()
          .then((data: UpdatePendingInvitationUserStatusResponse) => {
            if (data?.success) {
              toastSuccessMessage('Contractor has been declined successfully')
            } else {
              toastErrorMessage(
                data?.message || 'Unable to Declined Contractor',
              )
            }
          })
          .catch((error) => {
            toastErrorMessage(
              error?.data?.message || 'Unable to Declined Contractor',
            )
          })
      }
    })
  }

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )
  const { pendingInvitationUsers: pendingInvitationUsersQueryParams } =
    listingQueryParams

  const { data, isFetching, isSuccess } = usePendingInvitationUsersDetailsQuery(
    pendingInvitationUsersQueryParams,
  )

  useEffect(() => {
    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        pendingInvitationUsers: {
          q: search,
          page: page,
          page_size: pageSize,
        },
      }),
    )
  }, [search, page, pageSize])

  return (
    <>
      {/* Table */}
      <Box>
        <DataTable
          headers={[
            '#',
            'Full Name',
            'Mobile Number',
            'State',
            'District',
            'Area',
            'DOB',
            'Status',
            'View',
            'Actions',
          ]}
          data={data?.results}
          totalPages={data?.count}
          page={page}
          rowsPerPage={pageSize}
          handlePageChange={handlePageChange}
          handleSearchValue={handleSearchValue}
          handleOpenViewModal={handleOpenViewModal}
          handleApprovedUserInvitation={handleApprovedUserInvitation}
          handleDeclinedUserInvitation={handleDeclinedUserInvitation}
          handleChangeRowsPerPage={handleChangePageSize}
          isSuccess={isSuccess && !isFetching}
        />
      </Box>

      {/* View Modal */}
      {!!openViewModal?.id && (
        <ViewPendingUserModal
          id={openViewModal?.id}
          openModal={openViewModal?.open}
          handleCloseModal={handleCloseViewModal}
        />
      )}
    </>
  )
}

export default PendingInvitation
