import HomeIcon from '@mui/icons-material/Home'

import { Box, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { APP_CONFIG } from '../../../constants/contants'
import { setListingQueryParams } from '../../../store/slice/commonSlice'
import {
  useLogsReportDetailsQuery,
  useUserReportDetailsQuery,
} from '../../../api/reportsApi'
import { DateRange } from '@mui/x-date-pickers-pro'
import dayjs, { Dayjs } from 'dayjs'
import TopBar from '../../../components/TopBar'
import routeUrls from '../../../constants/routeUrls'
import { HeaderType } from './DataTable/types'
import DataTable from './DataTable'
import { useApprovedUsersDetailsQuery } from '../../../api/userApi/approvedUsersApi'
import useLogsReportsModal from './useLogsReportsModal'

const LogsReports = () => {
  const dispatch = useDispatch<AppDispatch>()

  const { control, watch } = useLogsReportsModal()

  // BreadCrumbsItems
  const breadcrumbsItems = [
    {
      to: routeUrls.DASHBOARD,
      text: 'Home',
      icon: <HomeIcon sx={{ mr: 0.5 }} fontSize='inherit' />,
    },
    { text: 'Logs Reports' },
  ]

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )

  const { logsReports: logsReportsQueryParams } = listingQueryParams

  // Page
  const [page, setPage] = useState(APP_CONFIG.DEFAULT_PAGE)
  const [pageSize, setPageSize] = useState(APP_CONFIG.DEFAULT_PAGE_SIZE)

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage + 1)
  }

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(APP_CONFIG.DEFAULT_PAGE)
  }

  // Get All User List
  const [userSearch, setUserSearch] = useState<string>('')
  const { approvedUsers: approvedUsersQueryParams } = listingQueryParams

  const {
    data: userList,
    isFetching: isUserListFetching,
    isSuccess: isUserListSuccess,
  } = useApprovedUsersDetailsQuery(approvedUsersQueryParams)

  useEffect(() => {
    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        approvedUsers: {
          q: userSearch,
          page: APP_CONFIG.DEFAULT_PAGE,
          page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
        },
      }),
    )
  }, [userSearch])

  // Date Range Value
  const [dateRangeValue, setDateRangeValue] = useState<DateRange<Dayjs>>([
    null,
    null,
  ])

  const handleDateRangeValue = (newValue: DateRange<Dayjs>) => {
    setDateRangeValue(newValue)
  }

  const { data, isFetching, isSuccess } = useLogsReportDetailsQuery(
    logsReportsQueryParams,
  )

  // Clear Filters
  const handleClearFilters = () => {
    setDateRangeValue([null, null])
  }

  const userId = watch('user')

  useEffect(() => {
    // Extract the start and end date in 'YYYY-MM-DD' format
    const startDate = dateRangeValue[0]
      ? dayjs(dateRangeValue[0]).format('YYYY-MM-DD')
      : undefined
    const endDate = dateRangeValue[1]
      ? dayjs(dateRangeValue[1]).format('YYYY-MM-DD')
      : undefined

    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        logsReports: {
          user_id: userId,
          page: page,
          page_size: pageSize,
          start_date: startDate, // Pass start date
          end_date: endDate, // Pass end date
        },
      }),
    )
  }, [userId, page, pageSize, dateRangeValue])

  const [headers, setHeaders] = useState<HeaderType[]>([
    {
      id: 1,
      name: '#',
    },
    {
      id: 2,
      name: 'Contractor Name',
    },
    {
      id: 3,
      name: 'District Name',
    },
    {
      id: 4,
      name: 'Token',
    },
    {
      id: 5,
      name: 'Point',
    },
    {
      id: 6,
      name: 'Date',
    },
    {
      id: 7,
      name: 'Time',
    },
  ])

  return (
    <>
      <TopBar title='Logs Reports' breadcrumbsItems={breadcrumbsItems} />

      {/* Table */}
      <Box mt={2}>
        <DataTable
          headers={headers}
          action={
            <Button
              variant='contained'
              onClick={handleClearFilters}
              sx={{ textTransform: 'none', fontWeight: 600 }}
            >
              Clear Filter
            </Button>
          }
          data={data?.results}
          totalPages={data?.count}
          page={page}
          rowsPerPage={pageSize}
          handlePageChange={handlePageChange}
          handleChangeRowsPerPage={handleChangePageSize}
          dateRangeValue={dateRangeValue}
          handleDateRangeValue={handleDateRangeValue}
          isSuccess={isSuccess && !isFetching}
          userList={userList?.results}
          isUserListLoading={!isUserListSuccess && isUserListFetching}
          setUserSearch={setUserSearch}
          control={control}
        />
      </Box>
    </>
  )
}

export default LogsReports
