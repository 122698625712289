import HomeIcon from '@mui/icons-material/Home'
import routeUrls from '../../constants/routeUrls'
import { TabItem } from '../../components/TabPanelSwitcher/types'
import { Box } from '@mui/material'
import TabPanelSwitcher from '../../components/TabPanelSwitcher'
import TopBar from '../../components/TopBar'
import StockCreditedTransactions from './StockCreditedTransactions'
import StockDebitedTransactions from './StockDebitedTransactions'

const StockTransaction = () => {
  // BreadCrumbsItems
  const breadcrumbsItems = [
    {
      to: routeUrls.DASHBOARD,
      text: 'Home',
      icon: <HomeIcon sx={{ mr: 0.5 }} fontSize='inherit' />,
    },
    { text: 'Redeem Points' },
  ]

  // Tabs List
  const tabsList: TabItem[] = [
    {
      label: 'Stock Credited Transactions',
      value: '1',
      Component: StockCreditedTransactions,
    },
    {
      label: 'Stock Debited Transactions',
      value: '2',
      Component: StockDebitedTransactions,
    },
  ]
  return (
    <>
      <TopBar title='Stock Transaction' breadcrumbsItems={breadcrumbsItems} />

      {/* Table */}
      <Box mt={2}>
        <TabPanelSwitcher tabs={tabsList} fullWidth />
      </Box>
    </>
  )
}

export default StockTransaction
