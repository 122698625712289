import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { Controller } from 'react-hook-form'
import { OptionsProps, SelectFieldProps } from './types'
import { debounce } from 'lodash'

const SelectField: React.FC<SelectFieldProps> = ({
  name,
  control,
  options = [],
  label,
  loading = false,
  fullWidth = false,
  required = false,
  errors = false,
  helperText = '',
  readOnly = false,
  getOptionLabel,
  getOptionValue,
  onSearch,
  searchEnabled = true,
  selectedOption,
  onSelect,
}) => {
  const mergedOptions = React.useMemo(() => {
    if (selectedOption) {
      const selectedValue = getOptionValue(selectedOption)
      const isSelectedOptionInOptions = options.some(
        (option) => getOptionValue(option) === selectedValue,
      )

      if (!isSelectedOptionInOptions) {
        return [selectedOption, ...options]
      }
    }
    return options
  }, [options, selectedOption, getOptionValue])

  const handleSearch = React.useMemo(() => {
    if (!onSearch) return undefined
    return debounce((event: React.ChangeEvent<{}>, value: string) => {
      if (onSearch && searchEnabled) {
        onSearch(value)
      }
    }, 500)
  }, [onSearch, searchEnabled])

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field }) => (
        <Autocomplete
          id={name}
          fullWidth={fullWidth}
          loading={loading}
          options={loading ? [] : mergedOptions}
          readOnly={readOnly}
          isOptionEqualToValue={(option, value) =>
            getOptionValue(option) === getOptionValue(value)
          }
          getOptionLabel={getOptionLabel}
          value={
            mergedOptions.find(
              (option) => getOptionValue(option) === field.value,
            ) || null
          }
          onChange={(_, value) => {
            if (value) {
              field.onChange(value?.id) // Set field value to the selected option's id
              onSelect?.(value)
            } else {
              field.onChange(null) // Handle clear action by setting value to null
              onSelect?.(null)
            }
          }}
          onInputChange={handleSearch}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={errors}
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color='inherit' size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              sx={{
                borderRadius: '10px',
                '& .MuiInputBase-root': {
                  borderRadius: '10px',
                },
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'rgb(248, 250, 252)',
                },
              }}
            />
          )}
        />
      )}
    />
  )
}

export default SelectField
