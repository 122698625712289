import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useRef, useState } from 'react'
import { AddModalForm, AddModalProps } from './types'
import { addModalValidationSchema } from './validation'
import {
  toastErrorMessage,
  toastSuccessMessage,
} from '../../../utils/toastMessage'
import { useAddQRCodeMutation } from '../../../api/qrCodeApi'

const useAddModal = ({ openModal, handleCloseModal }: AddModalProps) => {
  const [addQRCode, { isLoading }] = useAddQRCodeMutation()

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm<AddModalForm>({
    defaultValues: {
      product: undefined,
      productName: '',
      batchNumber: '',
      packing: undefined,
      points: undefined,
      quantity: undefined,
    },
    resolver: yupResolver(addModalValidationSchema),
  })

  // Watch the values of packing and points
  const packing = watch('packing')
  const points = watch('points')
  const quantity = watch('quantity')

  // Use useEffect to calculate totalPoints
  useEffect(() => {
    if (packing && points) {
      setValue('totalPoints', packing * points)
    } else {
      setValue('totalPoints', undefined) // Clear the totalPoints field if either is empty
    }
  }, [packing, points, setValue])

  useEffect(() => {
    if (packing && quantity) {
      setValue('totalQuantity', packing * quantity)
    } else {
      setValue('totalQuantity', undefined) // Clear the totalPoints field if either is empty
    }
  }, [packing, quantity, setValue])

  const onSubmit: SubmitHandler<AddModalForm> = async (data) => {
    const formData = new FormData()

    formData.append('product_id', data.product.toString())
    formData.append('batch_no', data.batchNumber.toString())
    formData.append('packing', data.packing.toString())
    formData.append('points', data.points.toString())
    formData.append('quantity', data.quantity.toString())

    addQRCode(formData)
      .unwrap()
      .then((data) => {
        if (data.success) {
          toastSuccessMessage('Bar Code Created Successfully')
          handleCloseModal()
          if (data.zip_url) {
            window.open(data.zip_url, '_blank') // Open the URL in a new tab
          }
        } else {
          toastErrorMessage(data.message || 'Unable to Create Bar Code')
        }
      })
      .catch((error) => {
        toastErrorMessage(error.data?.message || 'Unable to Create Bar Code')
      })
  }

  return {
    control,
    register,
    errors,
    onSubmit,
    handleSubmit,
    isLoading,
    reset,
    watch,
    setValue,
  }
}

export default useAddModal
