import { FC } from 'react'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ViewPendingInvitationModalProps } from './types'
import { TabItem } from '../../../../components/TabPanelSwitcher/types'
import TabPanelSwitcher from '../../../../components/TabPanelSwitcher'
import GiftDetails from './GiftDetails'
import RedeemStatus from './RedeemStatus'
import UserDetails from './UserDetails'
import { useGetSingleRedeemPointsPendingDetailsQuery } from '../../../../api/redeemPointsApi/redeemPointsPendingApi'

const ViewPendingInvitationModal: FC<ViewPendingInvitationModalProps> = ({
  id,
  openModal,
  handleCloseModal,
}) => {
  // Fetching Product Data
  const { data, isSuccess, isFetching } =
    useGetSingleRedeemPointsPendingDetailsQuery(
      {
        id: id,
      },
      {
        skip: !openModal, // Skip the query if the modal is not open
      },
    )

  const tabsList: TabItem[] = [
    {
      label: 'Contractor Details',
      value: '1',
      Component: UserDetails,
      props: { data: data?.data },
    },
    {
      label: 'Gift Details',
      value: '2',
      Component: GiftDetails,
      props: { data: data?.data },
    },
    {
      label: 'Redeem Status',
      value: '3',
      Component: RedeemStatus,
      props: { data: data?.data },
    },
  ]

  return (
    <Dialog
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
        },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle
        sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }}
        id='customized-dialog-title'
      >
        View Pending Invitation Details
        <IconButton
          aria-label='close'
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {isFetching || !isSuccess ? (
          <Box textAlign='center'>
            <CircularProgress />
          </Box>
        ) : (
          <TabPanelSwitcher tabs={tabsList} fullWidth />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ViewPendingInvitationModal
