import { useForm } from 'react-hook-form'

const useLogsReportsModal = () => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      user: undefined,
    },
  })
  return {
    watch,
    control,
  }
}

export default useLogsReportsModal
