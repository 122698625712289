import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { useEffect, useState } from 'react'
import { APP_CONFIG } from '../../../constants/contants'
import { useGetAllStocksDebitedTransactionListQuery } from '../../../api/stocksTransactionsApi'
import { setListingQueryParams } from '../../../store/slice/commonSlice'
import DataTable from './DataTable'

const StockDebitedTransactions = () => {
  const dispatch = useDispatch<AppDispatch>()

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )

  const { stockDebitedTransaction: stockDebitedTransactionQueryParams } =
    listingQueryParams

  // Page
  const [page, setPage] = useState(APP_CONFIG.DEFAULT_PAGE)
  const [pageSize, setPageSize] = useState(APP_CONFIG.DEFAULT_PAGE_SIZE)

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage + 1)
  }

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(APP_CONFIG.DEFAULT_PAGE)
  }

  // Search Value
  const [search, setSearch] = useState('')

  const handleSearchValue = (value: string) => {
    setSearch(value)
  }

  const { data, isFetching, isSuccess } =
    useGetAllStocksDebitedTransactionListQuery(
      stockDebitedTransactionQueryParams,
    )

  useEffect(() => {
    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        stockDebitedTransaction: {
          q: search,
          page: page,
          page_size: pageSize,
        },
      }),
    )
  }, [search, page, pageSize])

  return (
    <>
      {/* Table */}
      <Box>
        <DataTable
          headers={['#', 'Product Name', 'Quantity', 'Created At']}
          data={data?.results}
          totalPages={data?.count}
          page={page}
          rowsPerPage={pageSize}
          handlePageChange={handlePageChange}
          handleSearchValue={handleSearchValue}
          handleChangeRowsPerPage={handleChangePageSize}
          isSuccess={isSuccess && !isFetching}
        />
      </Box>
    </>
  )
}

export default StockDebitedTransactions
