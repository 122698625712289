import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import useAddModal from './useAddModal'
import InputField from '../../../components/InputField'
import ButtonField from '../../../components/ButtonField'
import { useEffect, useState } from 'react'
import { AddStockModalProps } from './types'
import SelectField from '../../../components/SelectField'
import { useProductsDetailsQuery } from '../../../api/productsApi'
import { RootState } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import { setListingQueryParams } from '../../../store/slice/commonSlice'
import { APP_CONFIG } from '../../../constants/contants'
import { ProductResponse } from '../../../api/productsApi/types'

const AddStockModal: React.FC<AddStockModalProps> = ({
  openModal,
  handleCloseModal,
}) => {
  const { isLoading, control, handleSubmit, onSubmit, errors, reset } =
    useAddModal({
      handleCloseModal,
    })

  const dispatch = useDispatch()

  // Listing Query Params
  const listingQueryParams = useSelector(
    (state: RootState) => state.commonReducer.listingQueryParams,
  )

  // Product List Query
  const [productSearch, setProductSearch] = useState('')
  const { products: productsQueryParams } = listingQueryParams

  const {
    data: productList,
    isFetching: isProductFetching,
    isSuccess: isProductSuccess,
  } = useProductsDetailsQuery(productsQueryParams)

  useEffect(() => {
    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        products: {
          q: productSearch,
          page: APP_CONFIG.DEFAULT_PAGE,
          page_size: APP_CONFIG.DEFAULT_PAGE_SIZE,
        },
      }),
    )
  }, [productSearch, openModal])

  // reset data when open the modal
  useEffect(() => {
    reset()
  }, [openModal])

  return (
    <Dialog
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          borderRadius: 4,
        },
      }}
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* Title */}
      <DialogTitle sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }} id='add-stock'>
        Add Stock
      </DialogTitle>

      {/* Dialog box close button */}
      <IconButton
        onClick={handleCloseModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Grid container spacing={1}>
          {/* Products List Field */}
          <Grid item xs={12} mt={2}>
            <SelectField
              label={'Products List'}
              name={'product'}
              options={productList?.results}
              loading={!isProductSuccess && isProductFetching}
              control={control}
              getOptionLabel={(option) => option.product_name} // Use 'name' as the label
              getOptionValue={(option) => option.id} // Use 'id' as the value
              onSearch={setProductSearch}
              errors={!!errors.product}
              helperText={errors.product ? errors.product.message : ''}
            />
          </Grid>

          {/* Points */}
          <Grid item xs={12}>
            <InputField
              name={'quantity'}
              control={control}
              label={'Stock Quantity'}
              type={'number'}
              placeholder='Enter the stocks quantity'
              fullWidth
              required
              errors={!!errors.quantity}
              helperText={errors.quantity ? errors.quantity.message : ''}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <ButtonField
          variant='contained'
          onClick={handleCloseModal}
          buttonText={'Cancel'}
          sx={{
            backgroundColor: 'primary.main',
          }}
        />

        <ButtonField
          type='submit'
          loading={isLoading}
          variant='contained'
          buttonText={'Save'}
          sx={{
            backgroundColor: 'ternary.main',
            '&:hover': {
              backgroundColor: 'ternary.dark',
            },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export default AddStockModal
