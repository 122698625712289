import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material'
import dayjs from 'dayjs'
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo'
import { DataTableProps } from './types'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import SelectField from '../../../../components/SelectField'

export default function DataTable({
  headers,
  data,
  page,
  rowsPerPage,
  handlePageChange,
  handleChangeRowsPerPage,
  totalPages,
  isSuccess,
  action,
  dateRangeValue,
  handleDateRangeValue,

  userList,
  isUserListLoading,
  setUserSearch,
  control,
}: DataTableProps) {
  return (
    <Card elevation={0} sx={{ borderRadius: 3 }}>
      <CardHeader
        sx={{
          display: 'flex',
          flexDirection: 'column', // Stack title and action vertically on small screens
          alignItems: 'flex-start', // Align content to the start
          '.MuiCardHeader-action': {
            alignSelf: 'end',
            mr: 1,
            '@media (min-width:480px)': {
              alignSelf: 'center', // Align action to center on larger screens
            },
          },
          '@media (min-width:480px)': {
            flexDirection: 'row', // Default behavior on larger screens
            alignItems: 'center', // Align content to the center
          },
        }}
        title={
          <Grid container>
            <Grid xs={12} md={5}>
              <SelectField
                label={'Contractor'}
                name={'user'}
                options={userList}
                loading={isUserListLoading}
                control={control}
                getOptionLabel={(option) => option.full_name} // Use 'name' as the label
                getOptionValue={(option) => option.id} // Use 'id' as the value
                onSearch={setUserSearch}
                required
              />
            </Grid>
            <Grid xs={0} md={1}></Grid>
            <Grid xs={12} md={5}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem component='DateRangePicker'>
                  <MobileDateRangePicker
                    value={dateRangeValue}
                    onChange={handleDateRangeValue}
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid>
          </Grid>
        }
        action={action}
      />

      <Divider />

      <CardContent sx={{ px: 0, pb: 0 }}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell key={index} sx={{ fontWeight: 600 }}>
                  <TableSortLabel active={header.id === 5}>
                    {header.name}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isSuccess ? (
              data && data.length > 0 ? (
                data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component='th' scope='row'>
                      {(page - 1) * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell>{row?.user_name}</TableCell>
                    <TableCell>{row?.district_name}</TableCell>
                    <TableCell>{row?.token}</TableCell>
                    <TableCell>{row?.points}</TableCell>
                    <TableCell>{row?.date}</TableCell>
                    <TableCell>{row?.time}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={headers.length} align='center'>
                    No records found
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell
                  sx={{ textAlign: 'center', py: 5 }}
                  colSpan={headers.length}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>

      <CardActions
        sx={{
          justifyContent: 'end',
        }}
      >
        <TablePagination
          component='div'
          color='primary'
          count={totalPages || 0}
          page={page - 1}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CardActions>
    </Card>
  )
}
